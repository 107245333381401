import __request__ from 'routing/request';
interface PipelineSource {
    readonly stages: StageSource[];
    readonly description: string;
    readonly created_at: string;
    readonly id: string;
    readonly source: string;
    readonly title: string;
    readonly modified_at: string;
    readonly errors: ParseError[];
}
interface PaginatedResponse_PipelineSource {
    readonly [_key: string]: Object;
}
interface StageSource {
    readonly stage: number;
    readonly match: 'ALL' | 'EITHER' | 'PASS';
    readonly rules: string[];
}
type PipelineSourceArray = PipelineSource[];
interface RoutingRequest {
    readonly stream_id: string;
    readonly remove_from_default: boolean;
    readonly input_id: string;
}
interface ParseError {
    readonly line: number;
    readonly position_in_line: number;
    readonly type: string;
}
interface RoutingResponse {
    readonly rule_id: string;
}
/**
 * Create a processing pipeline from source
 */
export function createFromParser(pipeline: PipelineSource): Promise<PipelineSource> {
    return __request__('POST', '/system/pipelines/pipeline', pipeline, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all processing pipelines
 */
export function getAll(): Promise<PipelineSourceArray> {
    return __request__('GET', '/system/pipelines/pipeline', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of pipelines
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'title' | 'description' | 'id' = 'title', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | ' desc' = 'asc'): Promise<PaginatedResponse_PipelineSource> {
    return __request__('GET', '/system/pipelines/pipeline/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Parse a processing pipeline without saving it
 */
export function parse(pipeline: PipelineSource): Promise<PipelineSource> {
    return __request__('POST', '/system/pipelines/pipeline/parse', pipeline, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add a stream routing rule to the default routing pipeline.
 */
export function routing(body: RoutingRequest): Promise<RoutingResponse> {
    return __request__('PUT', '/system/pipelines/pipeline/routing', body, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a processing pipeline
 */
export function get(id?: string): Promise<PipelineSource> {
    return __request__('GET', `/system/pipelines/pipeline/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Modify a processing pipeline
 */
export function update(pipeline: PipelineSource, id?: string): Promise<PipelineSource> {
    return __request__('PUT', `/system/pipelines/pipeline/${id}`, pipeline, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a processing pipeline
 */
export function remove(id?: string): Promise<void> {
    return __request__('DELETE', `/system/pipelines/pipeline/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
